body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-title-content {
  text-transform: uppercase;
  font-weight: 700;
}

.box {
  /* background-color: linear-gradient(red, #fbfbfb); */
  background-color: #fbfbfb;
  padding: 25px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  border-radius: 3px 3px 0 0;
}

.sub-box {
  padding: 25px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  border-radius: 3px 3px 0 0;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.box.danger {
  /* background-color: rgba(237, 57, 55, 0.9); */
  border: 1px solid red;
}

.box.small {
  padding: 5px;
}

.box-shadow {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.03);
}

.hover-opacity:hover {
  opacity: 0.7;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: #eee;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
}
