/* OpenAPI Viewer Styles */

.ctot-code-main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.ctot-code-main-container h4 {
  margin-bottom: 5px;
}

.ctot-code-layout-container {
  display: grid;
  grid-template-columns: minmax(0, 2fr) 400px; /* Fixed width for right panel */
  gap: 30px;
  margin: 40px 0;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background-color: #fff;
}

.ctot-code-layout-content-left {
  padding-right: 20px;
  border-right: 1px solid #e8e8e8;
  min-width: 0; /* Prevent content from overflowing */
}

.ctot-code-layout-content-right {
  padding-left: 20px;
  width: 400px; /* Fixed width */
}

/* Rest of the styles remain the same */
.ctot-code-section {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.ctot-code-section-title {
  margin: 25px 0 15px;
  font-size: 1.2em;
  color: #2c3e50;
  font-weight: 600;
}

.ctot-code-param {
  background-color: #e9ecef;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
  color: #e83e8c;
}

.ctot-code-param-addon {
  color: #6c757d;
  font-size: 0.9em;
  margin-left: 8px;
}

.ctot-code-server-viewer-item {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
  border-left: 4px solid #28a745;
}

.ctot-code-response-container-content {
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.ctot-code-indent {
  margin-left: 5px;
  padding-left: 15px;
  border-left: 2px solid #dee2e6;
}

.ctot-code-block-medium {
  margin: 10px 0;
  padding: 15px;
  border-radius: 6px;
  background-color: #282a36;
  color: #f8f8f2;
}

/* Code Examples Styles */
.ctot-code-examples-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
  overflow: hidden;
}

.ctot-code-lang-buttons-container {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
}

.ctot-code-lang-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.ctot-code-lang-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9em;
}

.ctot-code-lang-button:hover {
  background-color: #f8f9fa;
}

.ctot-code-lang-button.active {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.ctot-code-lang-button img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.ctot-code-block-container {
  padding: 15px;
  background-color: #282a36;
  border-radius: 0 0 6px 6px;
}

.ctot-code-block {
  margin: 0 !important;
  padding: 15px !important;
  border-radius: 4px;
  font-family: "Fira Code", monospace !important;
  font-size: 0.9em !important;
  line-height: 1.5 !important;
  max-width: 100%;
  overflow-x: auto;
}

/* Response View Styles */
.ctot-code-responses-container {
  margin: 20px 0;
}

.ctot-code-response-content {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.ctot-code-response-schema {
  margin-top: 15px;
}

.ctot-code-response-type {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ctot-code-response-description {
  margin: 10px 0;
  color: #666;
  font-size: 0.95em;
}

.ctot-code-response-properties {
  margin: 20px 0;
}

.ctot-code-property-header {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;

  justify-items: start;
  align-items: start;
}

.ctot-code-properties-grid {
  display: grid;
  gap: 12px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #eee;
}

.ctot-code-property-row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 15px;
  align-items: start;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.ctot-code-property-row:last-child {
  border-bottom: none;
}

.ctot-code-property-name {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ctot-code-property-name .required-star {
  color: #ff4d4f;
  margin-left: 4px;
}

.ctot-code-property-type {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ctot-code-property-description {
  color: #666;
  font-size: 0.9em;
}

.ctot-code-response-examples {
  margin: 20px 0;
}

.ctot-code-response-enum {
  margin: 15px 0;
}

.ctot-code-enum-values {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .ctot-code-layout-container {
    grid-template-columns: 1fr;
  }

  .ctot-code-layout-content-left {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
  }

  .ctot-code-layout-content-right {
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }

  .ctot-code-property-row {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .ctot-code-lang-buttons {
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 5px;
  }

  .ctot-code-lang-button {
    flex-shrink: 0;
  }
}
